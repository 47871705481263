import "./css/styles.css";
// import PageLanding from "./pages/Landing";
import Mint from "./components/Mint";
function App() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", function (accounts) {
      // Time to reload your interface with accounts[0]!
      window.location.reload();
    });
  }
  return (
      <div className="App">
           <Mint></Mint>
      </div>
  );
}

export default App;
