import { useEffect, useState } from "react";
import { Backend } from "../utils/backend";
import { Contract } from "../utils/contract";
import {
  checkIfWalletIsConnected,
  connectWallet,
  formatAddress,
} from "../utils/utils";
import { ReactComponent as Twitter } from "../img/Twitter.svg";
import { ReactComponent as Logo } from "../img/logo-raver.svg";
// import { utils } from "ethers";
import Video from "./Video";
const Mint = () => {
  const [wallet, setWallet] = useState("");
  const [stage, setStage] = useState();
  const [available, setAvailable] = useState(false);
  const [contract, setContract] = useState();
  const [signature, setSignature] = useState("");
  const [maxPremint, setMaxPremint] = useState();
  const [count, setCount] = useState(0);
  const [maxPurchase, setMaxPurchase] = useState();
  // const [price, setPrice] = useState();
  const [whitelisted, setWhitelisted] = useState(false);
  const [show, setShow] = useState(false);
  const mint = async () => {
    try {
      setAvailable(false);
      if (stage === "premint") {
        console.log(maxPremint);
        let tx = await contract.premint(count, maxPremint, signature);
        await tx.wait();
        setCount(0);
        setShow(true);
      }
      if (stage === "mint") {
        let tx = await contract.mint(count);
        await tx.wait();
        setCount(0);
        setShow(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setAvailable(true);
      await init();
    }
  };
  let init = async () => {
    let connected = await checkIfWalletIsConnected();
    setWallet(connected);
    if (!connected) return;
    let api = new Backend(window.ethereum);
    let response = await api.init();
    setStage(response[0].stage);
    let contract = new Contract(
      window.ethereum,
      response[0].contract_address,
      response[0].contract_abi
    );
    let max = await contract.walletRemaining();
    setMaxPurchase(max.toNumber());
    setContract(contract);
    // let price = await contract.price();
    // setPrice(utils.formatEther(price.toString()));
    if (response[0].stage === "none") {
      return setAvailable(false);
    }
    if (response[0].stage === "premint") {
      setAvailable(response.length === 2);
      if (response.length === 2) {
        setWhitelisted(true);
        let balance = await contract.balanceOf();
        let remaining = response[1].quantity - balance.toNumber();
        setSignature(response[1].signature);
        setMaxPremint(response[1].quantity);
        setCount(remaining > 0 ? remaining : 0);
        setMaxPurchase(remaining > 0 ? remaining : 0);
      }
    }
    if (response[0].stage === "mint") {
      setAvailable(true);
      let remaining = await contract.walletRemaining();
      remaining = remaining.toNumber();
      setMaxPurchase(remaining);
      setCount(remaining);
      return;
    }
    if (response[0].stage === "soldout") {
      setAvailable(false);
    }
  };

  const connect = async () => {
    setWallet(await connectWallet());
  };
  useEffect(() => {
    if (window.ethereum) {
      init();
    }
  }, []);
  return (
    <section id="mint" className="flex flex-col  space-y-0">
      <Video show={show} onClose={() => setShow(false)} />
      <div className="w-full justify-start   mt-4 flex lg:hidden">
      <a href="https://randomravers.com">          
          <Logo className="ml-2" />
      </a>
      </div>
      <div className="w-full hidden lg:flex justify-between items-center px-12">
        <div className="flex items-center">
          <a href="https://randomravers.com">
            <Logo className="ml-2" />
          </a>
        </div>
        <div className="flex items-center mr-4">
          <button
            onClick={connect}
            className={`${wallet ? "btn-connected" : "btn-connect2"}  mr-40`}
          >
            {wallet ? formatAddress(wallet) : "Connect to metamask"}
          </button>
          <a
            href="https://twitter.com/randomraversgg"
            target="_blank"
            rel="noreferrer"
          >
            <Twitter />
          </a>
        </div>
      </div>
      <div className="w-full flex justify-center lg:justify-end lg:items-end">
        <div className="container-mint ">
          <img
            src={require("../img/WelcomeToTheRaverse.png")}
            alt="welcome to the raverse"
            className="lg:h-96 2xl:h-[26rem] hidden lg:flex 2xl:-mr-10"
          ></img>
          <img
            src={require("../img/WelcomeToTheRaverse-mobile.png")}
            alt="welcome to the raverse"
            className="lg:hidden "
          />
          <div className="flex flex-col  w-full items-center mt-10 lg:mt-0 mx-auto ">
            <div className="flex items-center space-x-5 md:space-x-10 lg:mb-0 mb-10 ">
              <button
                onClick={() => {
                  setCount(count - 1);
                }}
                disabled={count < 1}
                className="button-mint-small  "
              >
                <span className="mx-auto font-bold text-2xl ">-</span>
              </button>
              <h6 className="text-[7rem]  font-bloomer ">
                {count < 10 ? `0${count}` : count}
              </h6>
              <button
                onClick={() => {
                  setCount(count + 1);
                }}
                disabled={count === maxPurchase}
                className="button-mint-small"
              >
                <span className="mt-1 mx-auto font-bold text-2xl">+</span>
              </button>
            </div>
            <button
              disabled={!available || count === 0}
              onClick={mint}
              className="btn-mint2"
            >
              Mint
            </button>
            <button
              onClick={connect}
              className="lg:hidden btn-connect font-DK-Bocadillo text-[1.25rem] "
            >
              {wallet ? formatAddress(wallet) : "Connect to metamask"}
            </button>

            {stage === "none" && (
              <span className="font-perfect text-xl">Not Available</span>
            )}
            {stage === "premint" && whitelisted && (
              <span className="font-perfect text-xl">You're whitelisted</span>
            )}
            {stage === "premint" && !whitelisted && (
              <span className="font-perfect text-xl">
                You are not whitelisted
              </span>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Mint;
