const Video = ({ show, onClose }) => {
  if (show) {
    return (
      <div className="h-screen w-full fixed z-50 bg-black">
        <video
          className="h-full w-full hidden md:block"
          autoPlay
          onEnded={() => {
            onClose();
          }}
        >
          <source src={require("../videos/video.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video
          className="h-full w-full md:hidden"
          onEnded={() => {
            onClose();
          }}
          controls
        >
          <source
            src={require("../videos/video-mobile.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  } else {
    return null;
  }
};
export default Video;
