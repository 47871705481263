import { ethers } from "ethers";
class Contract {
  constructor(ethereum, address, abi) {
    this.ethereum = ethereum;
    this.address = address;
    this.abi = abi;
  }
  provider() {
    return new ethers.providers.Web3Provider(this.ethereum);
  }
  signer() {
    return this.provider().getSigner();
  }
  create() {
    return new ethers.Contract(this.address, this.abi, this.signer());
  }
  async maxPurchase() {
    let contract = this.create();
    return await contract.MAX_PURCHASE();
  }
  async balanceOf() {
    let contract = this.create();
    return await contract.balanceOf(this.ethereum.selectedAddress);
  }
  async price() {
    let contract = this.create();
    return await contract.getPrice();
  }
  async walletRemaining() {
    let max = await this.maxPurchase();
    let minted = await this.balanceOf();
    return max.sub(minted);
  }
  async mint(quantity) {
    const contract = this.create();
    let price = await this.price();
    return await contract.mint(quantity, { value: price.mul(quantity) });
  }
  async premint(quantity, max, signature) {
    const contract = this.create();
    let price = await this.price();
    return await contract.preMint(quantity, max, signature, {
      value: price.mul(quantity),
    });
  }
}
export { Contract };
