import { settings } from "./settings";
import axios from "axios";
class Backend {
  constructor(eth) {
    this.ethereum = eth;
  }
  async init() {
    this.ethereum.on("chainChanged", () => {
      window.location.reload();
    });
    this.ethereum.on("accountsChanged", () => {
      window.location.reload();
    });
    // get contract info
    let response = await axios.get(settings.api);
    response = await response.data;

    if (response.stage === "premint" || response.stage === "none") {
      let user = await axios.get(
        `${settings.whitelistApi}${this.ethereum.selectedAddress}`
      );
      if (Object.entries(user.data).length === 0) {
        return [response];
      }
      return [response, user.data];
    }
    return [response];
  }
}
export { Backend };
