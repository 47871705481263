import axios from "axios";
import { settings } from "./settings";
export const checkIfWalletIsConnected = async () => {
  try {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    if (accounts.length !== 0) {
      return accounts[0];
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};
export const connectWallet = async () => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      alert("Get MetaMask!");
      return;
    }
    let response = await axios.get(settings.api);
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${response.data.chain_id}` }],
    });
    return accounts[0];
  } catch (error) {
    console.log(error);
  }
};
export const formatAddress = (string) => {
  return (
    string.slice(0, 5) + "..." + string.slice(string.length - 5, string.length)
  );
};
